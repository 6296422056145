/*-------------------------------------
    Template Name: eBill - Multipurpose Recharge & Billing Payment System
    Template URI: https://themeies.com
    Description: Multipurpose Recharge & Billing Payment System is the most Enticing, Creative and Modern Billing template
    Author: Themeies
    Author URI: https://themeies.com
    Version: 1.0
    Tags: Recharge, Mobile, Bill, Billing, gas
---------------------------------------*/

/*
    Table of Context
    -------------------------------
    * Common CSS
    * Preloader
    * Header style
    * Navigation menu
    * Form Section
    * Feature Section 
    * Tutor Section 
    * Call to action Section 
    * Nearby students section
    * Blog section
    * Yachts section
    * Yachts details section
    * Yachts video
    * Yachts Curriculum
    * Comments
    * Contact section
    * Pricing section
    * Page Feature
    * Login / Registration 
    * Footer
*/

/**
 * Fonts 
 * Roboto & Popins
 */
@import url('https://fonts.googleapis.com/css?family=Poppins:300,400,500,600,700|Roboto:300,400,500,700&display=swap');

// Partial files
@import 'reset';
@import 'variables';


/****
*DASHBOARD STYLE
***/
.shadow{
    box-shadow: 0 10px 30px rgba(24, 33, 99, 0.1);
}
.no-shadow{
    box-shadow: none !important;
}
.has-menu-child ul.dropdown-menu-md.sub-menu.profile-drop {
    position: absolute;
    right: 0;
    left: auto !important;
}

.header-pro-thumb {
    position: absolute;
    width: 35px;
    height: 35px;
    left: 0;
    margin-top: -5px;
}

.pro-menu-drop>a {
    padding-left: 40px !important;
}

.pro-menu-drop>a i {
    color: #1b1e21 !important
}

/***
 * PROFILE PAGE
***/
.admin-page-title {
    padding: 20px;

}

.admin-page-title h2 {
    font-size: 18px;
    color: #fff;
    padding: 0;
    margin: 0;
}

h3.text-5.font-weight-400.mb-3 {
    border-bottom: 1px solid #ddd;
    padding: 13px 15px;
    background: #dee3e4;
}

a.text-muted {
    color: #8e9a9d !important;
    position: relative;
    top: 8px;
}

.Profile-menu {
    display: block;
}

.Profile-menu ul {
    display: block;
    padding: 0 !important;
    margin: 0;
}

.Profile-menu ul li {
    display: block;
}

.accord {
    display: none;
}

.infoItems {
    position: relative;
    padding: 30px;
    border-bottom: 1px solid #ddd;
}

.infoItems a {
    font-weight: 700;
    color: #256be6;
    position: absolute;
    right: 15px;
    top: 15px;
}

span.icon-inside {
    position: absolute;
    top: 6px;
    right: 10px;
}

/*
  dashboard page
*/
.hero-section {
    background: url("../images/9.jpg");
    display: block;
    padding: 50px 0;
    position: relative;
    background-size: cover;
    background-attachment: fixed;
    background-position: center;

    &:before {
        content: '';
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        display: block;
        background: $maing;
        opacity: .9;
    }

    /*
    profile progressbar
    */
    .progress,
    .profile-item {
        width: 150px;
        height: 150px;
        // line-height: 150px;
        background: none;
        margin: 0 auto;
        box-shadow: none;
        position: relative;

        .rounded-circle {
            border-radius: 50% !important;
            top: 5px;
            position: relative;
            max-width: 143px;
        }

        .profile-thumb-edit.verify-icon {
            position: absolute;
            width: 35px;
            height: 35px;
            font-size: 17px;
            border-radius: 50%;
            padding: 8px;
            text-align: center;
            right: 7px;
        }
    }

    .progress:after {
        content: "";
        width: 100%;
        height: 100%;
        border-radius: 50%;
        border: 7px solid #eee;
        position: absolute;
        top: 0;
        left: 0;
    }

    .progress>span {
        width: 50%;
        height: 100%;
        overflow: hidden;
        position: absolute;
        top: 0;
        z-index: 1;
    }

    .progress .progress-left {
        left: 0;
    }

    .progress .progress-bar {
        width: 100%;
        height: 100%;
        background: none;
        border-width: 7px;
        border-style: solid;
        position: absolute;
        top: 0;
        border-color: #2ed06e;
    }

    .progress .progress-left .progress-bar {
        left: 100%;
        border-top-right-radius: 75px;
        border-bottom-right-radius: 75px;
        border-left: 0;
        -webkit-transform-origin: center left;
        transform-origin: center left;
    }

    .progress .progress-right {
        right: 0;
    }

    .progress .progress-right .progress-bar {
        left: -100%;
        border-top-left-radius: 75px;
        border-bottom-left-radius: 75px;
        border-right: 0;
        -webkit-transform-origin: center right;
        transform-origin: center right;
    }

    .progress .progress-value {
        display: flex;
        border-radius: 50%;
        font-size: 36px;
        text-align: center;
        line-height: 20px;
        align-items: center;
        justify-content: center;
        height: 100%;
        font-weight: 300;
    }

    .progress .progress-value div {
        margin-top: 10px;
    }

    .progress .progress-value span {
        font-size: 12px;
        text-transform: uppercase;
    }

    .progress[data-percentage="10"] .progress-right .progress-bar {
        animation: loading-1 1.5s linear forwards;
    }

    .progress[data-percentage="10"] .progress-left .progress-bar {
        animation: 0;
    }

    .progress[data-percentage="20"] .progress-right .progress-bar {
        animation: loading-2 1.5s linear forwards;
    }

    .progress[data-percentage="20"] .progress-left .progress-bar {
        animation: 0;
    }

    .progress[data-percentage="30"] .progress-right .progress-bar {
        animation: loading-3 1.5s linear forwards;
    }

    .progress[data-percentage="30"] .progress-left .progress-bar {
        animation: 0;
    }

    .progress[data-percentage="40"] .progress-right .progress-bar {
        animation: loading-4 1.5s linear forwards;
    }

    .progress[data-percentage="40"] .progress-left .progress-bar {
        animation: 0;
    }

    .progress[data-percentage="50"] .progress-right .progress-bar {
        animation: loading-5 1.5s linear forwards;
    }

    .progress[data-percentage="50"] .progress-left .progress-bar {
        animation: 0;
    }

    .progress[data-percentage="60"] .progress-right .progress-bar {
        animation: loading-5 1.5s linear forwards;
    }

    .progress[data-percentage="60"] .progress-left .progress-bar {
        animation: loading-1 1.5s linear forwards 1.5s;
    }

    .progress[data-percentage="70"] .progress-right .progress-bar {
        animation: loading-5 1.5s linear forwards;
    }

    .progress[data-percentage="70"] .progress-left .progress-bar {
        animation: loading-2 1.5s linear forwards 1.5s;
    }

    .progress[data-percentage="80"] .progress-right .progress-bar {
        animation: loading-5 1.5s linear forwards;
    }

    .progress[data-percentage="80"] .progress-left .progress-bar {
        animation: loading-3 1.5s linear forwards 1.5s;
    }

    .progress[data-percentage="90"] .progress-right .progress-bar {
        animation: loading-5 1.5s linear forwards;
    }

    .progress[data-percentage="90"] .progress-left .progress-bar {
        animation: loading-4 1.5s linear forwards 1.5s;
    }

    .progress[data-percentage="100"] .progress-right .progress-bar {
        animation: loading-5 1.5s linear forwards;
    }

    .progress[data-percentage="100"] .progress-left .progress-bar {
        animation: loading-5 1.5s linear forwards 1.5s;
    }

    @keyframes loading-1 {
        0% {
            -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
        }

        100% {
            -webkit-transform: rotate(36);
            transform: rotate(36deg);
        }
    }

    @keyframes loading-2 {
        0% {
            -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
        }

        100% {
            -webkit-transform: rotate(72);
            transform: rotate(72deg);
        }
    }

    @keyframes loading-3 {
        0% {
            -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
        }

        100% {
            -webkit-transform: rotate(108);
            transform: rotate(108deg);
        }
    }

    @keyframes loading-4 {
        0% {
            -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
        }

        100% {
            -webkit-transform: rotate(144);
            transform: rotate(144deg);
        }
    }

    @keyframes loading-5 {
        0% {
            -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
        }

        100% {
            -webkit-transform: rotate(180);
            transform: rotate(180deg);
        }
    }

    .progress {
        margin-bottom: 1em;
    }

    .profile-completeness .border {
        /* border: none !important; */
        width: 150px;
        height: 150px;
        border-radius: 50% !important;
        margin: 0 auto;
        position: relative;

        &.profile-step {
            a {
                color: #fff;
                display: block;
                cursor: pointer;
                position: relative;
                z-index: 9;
            }
        }
    }

    .custom-file {
        position: relative;
        z-index: 2;
        bottom: 7px;
    }

    .bg-icon {
        position: relative;
        font-size: 40px;
        left: 0;
        right: 0;
        margin: auto;
        // opacity: .06;
        /*transform: rotate(-45deg);*/
    }

    .profile-name {
        display: block;
        text-align: center;
        color: #fff;
        position: absolute;
        left: 0;
        right: 0;
        bottom: -20px;
        margin: 0;
    }

    .profile-item {
        // border: 7px solid #fff;
        background: #ffffff96;
        border-radius: 50%;
        text-align: center;
        display: block;

        &:hover {
            background: #fff;

            i {
                color: $primary
            }
        }

        position: relative;

        i {
            display: block;
            // width: 100%;
            top: 50px;
            font-size: 50px;
        }

        i.Verified-icon {
            background: #fff;
            border-radius: 50%;
            position: absolute;
            font-size: 24px;
            margin-top: 15px;
            top: 55%;
            right: 0px;
            // opacity: 0;

            &.fa-check-circle {
                color: #2ed06e;
            }
        }

        .bg-icon {
            color: #fff;
        }

        p.title {
            display: block;
            text-align: center;
            color: #fff;
            position: absolute;
            left: 0;
            right: 0;
            bottom: -50px;

        }
    }
}

.admin-heading {
    display: block;
    margin-bottom: 15px;
    padding: 10px 15px;
    border-left: 10px solid $primary;
    font-size: 20px;
    box-shadow: 0 5px 10px rgba(24, 33, 99, 0.1);
    background: #f7faff;
    position: relative;
    &:before{
        position: absolute;
        content: '';
        width: 100%;
        height: 100%;
        display: block;
        top: 0;
        left: 0;
        border: 1px solid #edebeb;
    }
}

.transaction-title {
    display: block;
    // padding: 10px 20px;
    font-weight: 700;
    .items{
        margin-bottom: 5px;
        padding: 10px;
        transition: $transition;
        border-radius: 5px;
        box-shadow: 0 5px 10px rgba(24, 33, 99, 0.1);
        border: 1px solid #edebeb;
    }
    .col:first-child{            
        max-width: 90px;
        text-align:left;
    }
    .col:nth-child(3){            
        max-width: 90px;
        text-align:left;
    }
    .col:nth-child(4){            
        max-width: 150px;
        text-align:left;
    }
}

button.close {
    position: absolute;
    right: 0;
    top: 0;
    padding: 8px 10px !important;
    margin: 0 !important;;
    clip-path: polygon(100% 0, 0 0, 100% 100%);
    background: $maing;
    width: 50px;
    height: 50px;
    opacity: 1;

    span {
        top: 4px;
        right: 7px;
        position: absolute;
        color: #fff;
    }
}

.modal-content {
    overflow: hidden;
}

.modal_header {
    padding: 30px;
    display: block;
    background: $offwhite;
    margin-bottom: 15px;

    .status {
        position: absolute;
        height: 25px;
        width: 100px;
        display: block;
        -webkit-transform: rotate(-45deg);
        transform: rotate(-45deg);
        text-align: center;
        color: #fff;
        left: -5%;
        top: 2%;
    }
}

.transaction-area {
    .items {
        border: 1px solid #edebeb;
        box-shadow: 0 5px 10px rgba(24, 33, 99, 0.1);
        margin-bottom: 15px;
        padding: 10px;
        transition: $transition;
        border-radius: 5px;

        &:first-child {
            border-radius: 0 0 5px 5px;
        }
        .col:first-child{            
            max-width: 90px;
            text-align:left;
        }
        .col:nth-child(4){            
            max-width: 150px;
            text-align:left;
        }
        .col:nth-child(3){            
            max-width: 90px;
            text-align:left;
        }
        &:hover {
            background: $maing;
            color: #fff;
        }

        .payments-status {
            display: block;
            cursor: pointer;
        }
    }

}

.sidebar {
    .admin-widget{
        text-align: center;
        box-shadow: 0 5px 10px rgba(24, 33, 99, 0.1);
        padding: 50px 15px;
        margin-bottom: 30px;
        border: 1px solid #edebeb;
        i.admin-overlay-icon{
            position: absolute;
            font-size: 100px;
            text-align: center;
            opacity: .1;
            left: 0;
            right: 0;
            margin: auto;
        }
    }

    .Profile-menu ul li a i {
        width: 24px;
        text-align: left;
    }

    .Profile-menu ul li a {
        width: 100%;
        text-align: left !important;
        color: #fff !important;
        padding: 10px 15px !important;
        background: $maing;
        font-size: 14px !important;
    }


    .Profile-menu ul li.active a {
        position: relative;
        background: $mainghover ;
    }

    .Profile-menu ul li.active a:before {
        content: '';
        width: 5px;
        height: 100%;
        position: absolute;
        left: 0;
        display: block;
        background: #256be6;
        top: 0;
    }
}

.table{
    .table-head{
        display: block;
        padding: 5px 15px;
    }
    .table-content{
        border-bottom: 1px solid #ddd;
        padding: 10px;
        -webkit-transition: all 300ms ease-in-out;
        transition: all 300ms ease-in-out;
        border-radius: 5px;
    }
    .table-sub{
        border-bottom: 1px solid #ddd;
    }

}
.profile-area{
    .infoItems{
        margin-bottom: 30px;
        // box-shadow: 0 5px 10px rgba(24, 33, 99, 0.1);
    }
    .accord{
        position: relative;
        padding: 15px;
        margin-bottom: 30px;
        button.close{
            margin: 0;
        }
        .btn{
            display: block;
            width: 100%;
        }
        .content-edit-area{
            display: block;
            margin-bottom: 30px;
            border-bottom: 1px solid #ddd;
            overflow: hidden;
            background: #fff;
            padding: 15px;
            box-shadow: 0 5px 10px rgba(24, 33, 99, 0.1);
        }
    }
    a.btn-link.pbtn {
        font-size: 14px;
        float: right;
        margin-top: 7px;
        position: relative;
    }
}
.accord{
    position: relative;
    border-bottom: 1px solid #ddd;
    .form-control, .custom-select{
        padding: .375rem .75rem;
    }
}
.account-card{
    display: block;
    background: $maing;
    color: #fff;
    padding: 15px;
    position: relative;
    overflow: hidden;
    p.card-holder-name {
        margin-bottom: 0;
    }
    img{
        width: auto;
        max-width: 100%;
    }
    .account-card-overlay{
        display: none;
        position: absolute;
        left: 0;
        bottom: 0;
        background: #000000d6;
        width: 100%;
        height: 40px;
        text-align: center;
        color: #fff;
        a{
            position: relative;
            position: relative;
            right: auto;
            top: 7px;
            color: #fff;
        }
    }
    &:hover .account-card-overlay{
        display: block;
    }
    p.valid-card {
        font-size: 10px;
        width: 30px;
        text-transform: uppercase;
        opacity: .3;
        line-height: 1;
        float: left;
        margin-right: 6px;
    }
    a.account-card-new{
        position: relative;
        text-align: center;
        color: #fff !important;
        top: auto;
        right: auto;
        padding: 14px 0;
        p{
            width: 100%;
            display: block;
            color: #fff;
        }
    }
    p.pirotry.text-right {
        position: absolute;
        width: 100px;
        height: 25px;
        background: #2ed06e;
        transform: rotate(45deg);
        text-align: center !important;
        right: -25px;
        font-size: 11px;
        top: 15px;
    }
}

// History page
.main-form.all-fillters-area {
    display: block;
    position: relative;
    top: 0;
    z-index: 1;

    ul.morebtn {
        float: left;
        width: 40px;

        li {
            width: 40px;
            height: 40px;
            display: flex;
            position: relative;
            text-align: center;
            cursor: pointer;
            background: $offwhite;
            color: $primary;
            padding: 5px 5px;
            outline: none;
            border: none;
            a{
                padding: 2px;
                text-align: center;
            }
            &.active {
                background: #fff;
                color: $primary;
            }
            &:hover ul {
                display: block;
            }

            ul {
                margin: 0;
                padding: 0;
                left: auto;
                right: 0;
                width: 150px;

                li {
                    display: block;
                    width: 100%;
                    height: auto;
                    padding: 5px 10px;
                    display: block;
                    text-align: left;
                    background: $primary;
                    color: #fff;

                    &:hover {
                        color: #4facfe;
                        background: #fff;
                    }

                }
            }
        }
    }

    .bill-items {
        position: relative;
        max-width: calc(100% - 40px);
        float: left;

        li {
            width: 87px;
            height: 40px;
            display: flex;
            font-size: 14px;
            cursor: pointer;
            justify-content: center;

            &.active{
                background: $maing;
                color: #fff;
            }

            background: $offwhite;
            color: $primary;
            padding: 7px 10px;
            outline: none;
            border: none;

            &:hover {
                border: none;
                outline: none;
                background: #fff;
                color: $primary;
            }

        }
    }

}
.all-fillters-area{
    margin-bottom: 15px;
}
#custom-filter-area{
    margin-bottom: 15px;
    .filter-area{
        position: relative;
        i.fa-calendar-alt {
            font-size: 20px;
            opacity: .7;
            position: absolute;
            top: 16px;
            left: 15px;
        }
        .export-area {
            display: inline-block;
            position: absolute;
            top: 12px;
            right: 15px;
        }
        input#custom-date {
            padding-left: 45px;
        }
        

    }
    
}
.daterangepicker .ranges li.active{
    background: $maing;
}
#notifications-box{
    .custom-control-label::before, .custom-control-label::after{
        width: 3rem;
        height: 3rem;
    }
    .custom-control-label::before{
        background: $maing;        
    }
    .custom-control-label{
        padding-left: 35px;
    }
    p{
        padding-left: 35px;
    }
    .noti-items{
        padding: 15px;
        display: block;
        margin-bottom: 30px;
        padding-bottom: 10px;
        box-shadow: 0 5px 10px rgba(24, 33, 99, 0.1);
        p{
            margin-bottom: 0;
        }
    }
}


// Recharge Order Page
.profile-content {
    padding: 0;
    display: block;
    position: relative;
    .form{
        margin: 15px 0;
        padding: 30px;
        padding-top: 0;
        box-shadow: 0 5px 10px rgba(24, 33, 99, 0.1);
        border: 1px solid #edebeb;
        p{
            margin-bottom: 0;
        }
        &.success{
            padding-top: 60px;
            padding-bottom: 60px;
            p{
                margin-bottom: 10px;
            }
            .fa-check-circle{
                font-size: 100px;
            }
        }
       &#request-send-money{
           p{
               margin-bottom: 10px;
           }
       }
        span.bg-success.free-charge {
            color: #fff;
            padding: 2px 5px;
            border-radius: 3px;
            font-size: 12px;
            position: relative;
            top: -2px;
        }
        span.icon-inside {
            position: absolute;
            top: 16px;
            right: 15px;
        }
        .Withdarw-header{
            background: $maing;
            text-align: center;
            color: #fff;
            padding: 30px;
            h3{
                color: #fff;
            }
            .av-balance{
                margin-top: 10px;
                margin: 0;
            }
        }
        .custom-select {
            // top: -1px;
        }
        .amaount-area {
            width: calc(100% - 277px);
            position: relative;
            a.full-amnt{
                position: absolute;
                right: 15px;
                top: 16px;
            }
        }
    }
    .coupon-box{
        display: none;
        .form-control{
            padding: 15px !important;
        }
    }

    .pager {
        display: flex;

        li {
            width: 50%;
            text-align: start;

            &:last-child {
                text-align: end;
            }

            a {}
        }
    }

    
    .products {
        .row {
            a {
                flex-grow: 1;
                height: 90px;
                border: 1px solid #cccccc;
                border-radius: 5px;
                background: transparent;
                font-weight: 500;
                display: flex;
                color: #202020;
                font-size: 18px;
                text-align: center;
                justify-content: center;
                align-items: center;
                box-shadow: 0 10px 30px rgba(24, 33, 99, 0.1);
                &:hover,
                &.selected {
                    background: $maing;
                    color: #fff;
                }

            }

            a.pakbtn {
                display: block !important;
                width: 100%;
                height: 90px;
                border: 1px solid #cccccc;
                border-radius: 5px;
                font-weight: 500;
                font-size: 18px;
                padding-top: 18px;
                box-shadow: 0 10px 30px rgba(24, 33, 99, 0.1);

                b,
                span {
                    width: 100%;
                }

                &:hover {
                    background: $maing;
                    color: #fff;
                }
            }
        }
    }

    .nav-pills {
        border: 1px solid #edebeb;
        overflow: hidden;
        box-shadow: 0 5px 10px rgba(24, 33, 99, 0.1);
    }

    .nav-pills .nav-item {
        -webkit-box-flex: 1;
        -ms-flex: 1;
        flex: 1;
    }

    .nav-pills .nav-item .nav-link {
        border-radius: 0;
        padding: 15px 0;
        text-align: center;
        position: relative;
        color: #5c5c5c;
        text-transform: capitalize;
        clip-path: polygon(75% 0%, 100% 50%, 75% 100%, 0% 100%, 25% 50%, 0% 0%);

    }

    .nav-pills .nav-item .nav-link.active {
        background: $maing;
        color: #fff;
        clip-path: polygon(75% 0%, 100% 50%, 75% 100%, 0% 100%, 25% 50%, 0% 0%);
    }

    .nav-pills .nav-item:last-child .nav-link.active {
        clip-path: polygon(100% 0%, 100% 50%, 100% 100%, 0% 100%, 25% 50%, 0% 0%);
    }

    .tab-content .tab-pane .form-group {
        margin-top: 32px;
    }

    .tab-content .tab-pane .form-group label {
        font-size: 21px;
        line-height: 31px;
        color: #202020;
        font-weight: 500;
        margin-bottom: 10px;
    }
    span.input-group-text.currency-icon {
        width: 50px;
        background: $primary;
        color: #fff;
        font-size: 20px;
        justify-content: center;
    }
    span.input-group-text {
        padding: 0;
        height: 60px;
        background: #fff;
    }
   .custom-select {
        height: 60px;
    }
    .tab-content .tab-pane .form-group .form-control {
        border-radius: 5px;
        height: 60px;
        -webkit-box-shadow: none;
        box-shadow: none;
        border: 1px solid #ddd;
        padding: 0 20px;
        box-shadow: 0 10px 30px rgba(24, 33, 99, 0.1);
    }
    .tab-content .tab-pane .form-group .custom-select{
        box-shadow: 0 10px 30px rgba(24, 33, 99, 0.1);
    }
    .tab-content .tab-pane .form-group textarea.form-control{
        height: 120px;
    }
    .tab-content .tab-pane .form-group .order-page-box {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
    }

    .tab-content .tab-pane .form-group .order-page-box .form-control {
        border-radius: 0;
        height: 60px;
        -webkit-box-shadow: none;
        box-shadow: none;
        border: 1px solid #ddd;
    }

    .tab-content .tab-pane .form-group .order-page-box .form-control:first-child {
        width: 120px;
        text-align: center;
        border-radius: 5px 0 0 5px;
        border-right: none;
        padding: 0 15px;
    }

    .tab-content .tab-pane .form-group .order-page-box .form-control:last-child {
        border-radius: 0 5px 5px 0;
        padding: 0 30px;
    }

    .tab-content .tab-pane .form-group .order-page-box .form-control:focus {
        border-color: #ddd;
    }

    .tab-content .tab-pane .operators .title {
        font-size: 21px;
        line-height: 31px;
        color: #202020;
        font-weight: 500;
        margin-bottom: -10px;
        margin-top: 30px;
    }

    .tab-content .tab-pane .operators .single-operator {
        border-radius: 5px;
        text-align: center;
        margin-top: 30px;
    }

    .tab-content .tab-pane .operators .single-operator .op-logo{
        // margin-bottom: 24px;
        border: 10px solid #ddd;
        cursor: pointer;
        box-shadow: 0 10px 30px rgba(24, 33, 99, 0.1);

        &.selected {
            border-color: $secondary;
        }
    }

    .tab-content .tab-pane .operators .single-operator h3 {
        color: #202020;
        font-size: 16px;
        font-weight: 500;
    }

    .tab-content .tab-pane .products h3.title {
        font-size: 21px;
        line-height: 31px;
        font-weight: 500;
        margin-bottom: 10px;
        margin-top: 30px;
    }

    .tab-content .tab-pane .products button {
        height: 90px;
        border: 1px solid #ddd;
        border-radius: 5px;
        font-weight: 500;
        font-size: 18px;
        margin-right: 18px;
        box-shadow: 0 10px 30px rgba(24, 33, 99, 0.1);
    }

    .tab-content .tab-pane .products span {
        display: block;
        font-size: 16px;
        // color: #5c5c5c;
    }

    .tab-content .tab-pane .order-summery h3.title {
        font-size: 21px;
        line-height: 31px;
        color: #202020;
        font-weight: 500;
        margin-bottom: 10px;
        margin-top: 30px;
    }

    .tab-content .tab-pane .order-summery .table thead {
        background: #f1f1f1;
    }

    .tab-content .tab-pane .order-summery .table thead tr th {
        color: #202020;
        font-size: 16px;
        font-weight: 500;
        border: none;
        padding: 18px 30px;
    }

    .tab-content .tab-pane .order-summery .table tbody tr:last-child {
        border-bottom: 1px solid #ddd;
    }

    .tab-content .tab-pane .order-summery .table tbody tr th,
    .tab-content .tab-pane .order-summery .table tbody tr td {
        color: #5c5c5c;
        font-weight: 400;
        padding: 18px 30px;
    }

    .tab-content .tab-pane .buttons {
        text-align: center;
        margin-top: 40px;
    }

    .tab-content .tab-pane .payment h3.title {
        font-size: 21px;
        line-height: 31px;
        color: #202020;
        font-weight: 500;
        margin-bottom: 22px;
        margin-top: 30px;
    }

    .tab-content .tab-pane .payment .single-payment {
        text-align: center;
        padding: 10px;
        border: 10px solid #ddd;
        border-radius: 5px;
        margin-bottom: 30px;
        min-height: 80px;
        position: relative;
        cursor: pointer;

        &.selected {
            border-color: $secondary;
        }

        img {
            position: absolute;
            width: calc(100% - 20px);
            top: 50%;
            transform: translateY(-50%);
        }
    }

    .tab-content .tab-pane .payment .single-payment h4 {
        color: #202020;
        text-transform: capitalize;
        font-size: 21px;
        margin-bottom: 20px;
    }

    .tab-content .tab-pane .credit-card {
        padding: 30px;
        border: 1px solid #ddd;
        border-radius: 5px;
        margin-bottom: 30px;
    }

    .tab-content .tab-pane .credit-card h3.title {
        font-size: 21px;
        line-height: 31px;
        color: #202020;
        font-weight: 500;
        margin-bottom: 22px;
        margin-top: 0px;
    }

    .tab-content .tab-pane .credit-card .form-group {
        margin-top: 0;
    }

    .tab-content .tab-pane .credit-card .form-group label {
        font-size: 16px;
        margin-bottom: 10px;
        color: #5c5c5c;
    }

    .tab-content .tab-pane .done {
        text-align: center;

        i {
            font-size: 100px;
        }
    }

    .tab-content .tab-pane .done h3.title {
        font-size: 21px;
        line-height: 31px;
        color: #202020;
        font-weight: 500;
        margin-bottom: 22px;
        margin-top: 30px;
    }

    .tab-content .tab-pane .done img {
        margin-bottom: 20px;
    }

    .tab-content .tab-pane .done p {
        line-height: 26px;
    }
    .tab-content{
        .tab-pane{

            .invoice-option{
                a.invoice-btn {
                    color: $primary;
                    margin: 5px;
                    font-size: 16px;
                    i{
                        margin-right: 3px;
                        display: inline-block;
                        font-size: 16px;
                    }
                }
            }
        }
    }
        
    input#mobilenumber2 {
        // padding-left: 104px !important;
    }

    /* Cards & Bank Accounts*/
    .account-card {
        position: relative;
        background: linear-gradient(-45deg, #6c6c6b, #9e9e9c);
    }

    .account-card.account-card-primary {
        background: $maing;
    }

    .account-card .account-card-expire {
        font-size: 8px;
        line-height: 10px;
    }

    .account-card .account-card-overlay {
        position: absolute;
        background: rgba(0, 0, 0, 0.85);
        top: 0px;
        left: 0px;
        height: 100%;
        width: 100%;
        -ms-flex-align: center;
        align-items: center;
        -ms-flex-pack: center;
        justify-content: center;
        display: -ms-flexbox;
        display: flex;
        opacity: 0;
        -webkit-transition: all 0.3s ease;
        transition: all 0.3s ease;
    }

    .account-card:hover .account-card-overlay {
        opacity: 1;
        -webkit-transition: all 0.3s ease;
        transition: all 0.3s ease;
    }

    .account-card-new {
        background: #f1f5f6;
        border: 1px solid #ebebeb;
    }

    .account-card .border-left,
    .account-card .border-right {
        border-color: rgba(250, 250, 250, 0.1) !important;
    }

    #filterTransactions {
        .icon-inside {
            top: 13px;
            right: 15px;
        }
    }
}


// /*
// Color platte Selector
// */
// // Gradient Background
// .banner .hero-slider .item::before, .header .menu .dropdown-menu li a:hover, .btn-default, .featured-offer .owl-carousel .owl-nav button, .we-feature .owl-carousel .owl-nav button, .process li:hover .icon-bg,
// .bg-blue, .callto-action,.foo-top ul.social-media li a i,.modalBody .outerblock, .modalBody .data-nav ul li.active,.btn-filled, .testimonial::before, .sidebar .tagcloud a,
// .header .menu li a:hover i.fa-user, .hero-section:before, .sidebar .Profile-menu ul li a, .sidebar .Profile-menu ul li.active a:before, #notifications-box .custom-control-label::before, .transaction-area .items:hover,
// .recharge-order-page .nav-pills .nav-item .nav-link.active, .recharge-order-page .products .row a:hover, .recharge-order-page .products .row a.selected, button.close,.account-card,
// .main-form.all-fillters-area .bill-items li.active, .daterangepicker .ranges li.active
// {
//     background: linear-gradient( 89.5deg,  rgba(246,114,128,1) 0.2%, rgba(248,177,149,1) 90.6% );
// }

// // Solid background
// .main-form .bill-items li a, .main-form ul.morebtn li a, .main-form ul.morebtn li ul li a, .custom-control-input:checked~.custom-control-label::before, .btn-default:hover, .we-work .portfolioITems .filters .button-group .button.is-checked, .foo-top ul.social-media li a:hover i
// , .btn-filled:hover, .pagination .current-page, .sidebar .tagcloud a:hover, .sidebar .Profile-menu ul li.active a, .main-form.all-fillters-area ul.morebtn li ul li, .page-item.active .page-link

// {
//     background: linear-gradient( 89.5deg,  #f67280 0.2%, #f67280 90.6% );
// }
// // Secoundery Background Color: 
// .we-work .portfolioITems .filters .button-group .button{
//     background: linear-gradient( 89.5deg,  #f8b195 0.2%, #f8b195 90.6% );

// }
// // Color
// .main-form .bill-items li.active a, .main-form .bill-items li a:hover, a, .header .menu li a:hover, .tagline, .iconBox i, .widget-navigation li a:hover, .footer .copyright a, .footer .copyright i,
// .page-feature .breadcrumb a, .sl-form .notice a, .post-item .post-content h3 a, .categories li a:hover, .meta-tags a:hover, .meta-tags span:hover, .pagination a:hover, .pagination span:hover,
// .comments .comments-body a, .text-primary, .profile-area a.btn-link.pbtn, .btn-link, .infoItems a, .main-form.all-fillters-area .bill-items li
// {
//     color: #f67280 ;    
// }
// .text-primary{
//     color: #f67280 !important;
// }
// // Secoundery Color: 
// .iconBox:hover i, .post-item .post-content h3 a:hover, a:hover, .recharge-order-page .tab-content .tab-pane .invoice-option a.invoice-btn, .main-form.all-fillters-area ul.morebtn li ul li:hover


// {
//     color: #f8b195 ;
// }
// // Text Clipping
// .header .nav-header .brand {
//     background: linear-gradient( 89.5deg,  rgba(246,114,128,1) 0.2%, rgba(248,177,149,1) 90.6% );
//     -webkit-background-clip: text;
//     -webkit-text-fill-color: transparent;
// }
// // Border
// .custom-control-input:checked~.custom-control-label::before, .pagination .current-page, .pagination a:hover, .pagination span:hover,
// .admin-heading, .recharge-order-page .tab-content .tab-pane .operators .single-operator .op-logo.selected, .page-item.active .page-link
// {
//     border-color:  #f67280;
// }
