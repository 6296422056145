* {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
}
body {
	color: #83868c;
	background-color: #fff;
	font-family: 'Roboto', sans-serif;
	font-weight: 400;
	font-size: 15px;
	line-height: 27px;
}
a {
	transition: all 300ms ease-in-out;
	&:hover, &:focus {
		text-decoration: none;
		outline: 0px;
	}
	
}
h1, h2, h3, h4, h5, h6 {
	color: #0c2e60;
	// font-family: 'Poppins', sans-serif;
	font-weight: 600;
}

img {
	display: block;
	width: 100%;
	height: auto;	
}
ul {
	margin: 0;
	padding: 0;
	list-style: none;
}
figure {
	margin: 0;
}
input {
	&:active, &:focus {
		outline: 0;
	}
}
section {
	display: block;
	position: relative;
	padding: 50px 0 60px;
	width: 100%;
}
