/*-------------------------------------
    Template Name: eBill - Multipurpose Recharge & Billing Payment System
    Template URI: https://themeies.com
    Description: Multipurpose Recharge & Billing Payment System is the most Enticing, Creative and Modern Billing template
    Author: Themeies
    Author URI: https://themeies.com
    Version: 1.0
    Tags: Recharge, Mobile, Bill, Billing, gas
---------------------------------------*/
/*
    Table of Context
    -------------------------------
    * Common CSS
    * Preloader
    * Header style
    * Navigation menu
    * Form Section
    * Feature Section 
    * Tutor Section 
    * Call to action Section 
    * Nearby students section
    * Blog section
    * Yachts section
    * Yachts details section
    * Yachts video
    * Yachts Curriculum
    * Comments
    * Contact section
    * Pricing section
    * Page Feature
    * Login / Registration 
    * Footer
*/
/**
 * Fonts 
 * Roboto & Popins
 */
@import url("https://fonts.googleapis.com/css?family=Poppins:300,400,500,600,700|Roboto:300,400,500,700&display=swap");
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  color: #83868c;
  background-color: #fff;
  font-family: 'Roboto', sans-serif;
  font-weight: 400;
  font-size: 15px;
  line-height: 27px;
}

a {
  transition: all 300ms ease-in-out;
}

a:hover, a:focus {
  text-decoration: none;
  outline: 0px;
}

h1, h2, h3, h4, h5, h6 {
  color: #0c2e60;
  font-weight: 600;
}

img {
  display: block;
  width: 100%;
  height: auto;
}

ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

figure {
  margin: 0;
}

input:active, input:focus {
  outline: 0;
}

section {
  display: block;
  position: relative;
  padding: 50px 0 60px;
  width: 100%;
}

/****
*DASHBOARD STYLE
***/
.shadow {
  box-shadow: 0 10px 30px rgba(24, 33, 99, 0.1);
}

.no-shadow {
  box-shadow: none !important;
}

.has-menu-child ul.dropdown-menu-md.sub-menu.profile-drop {
  position: absolute;
  right: 0;
  left: auto !important;
}

.header-pro-thumb {
  position: absolute;
  width: 35px;
  height: 35px;
  left: 0;
  margin-top: -5px;
}

.pro-menu-drop > a {
  padding-left: 40px !important;
}

.pro-menu-drop > a i {
  color: #1b1e21 !important;
}

/***
 * PROFILE PAGE
***/
.admin-page-title {
  padding: 20px;
}

.admin-page-title h2 {
  font-size: 18px;
  color: #fff;
  padding: 0;
  margin: 0;
}

h3.text-5.font-weight-400.mb-3 {
  border-bottom: 1px solid #ddd;
  padding: 13px 15px;
  background: #dee3e4;
}

a.text-muted {
  color: #8e9a9d !important;
  position: relative;
  top: 8px;
}

.Profile-menu {
  display: block;
}

.Profile-menu ul {
  display: block;
  padding: 0 !important;
  margin: 0;
}

.Profile-menu ul li {
  display: block;
}

.accord {
  display: none;
}

.infoItems {
  position: relative;
  padding: 30px;
  border-bottom: 1px solid #ddd;
}

.infoItems a {
  font-weight: 700;
  color: #256be6;
  position: absolute;
  right: 15px;
  top: 15px;
}

span.icon-inside {
  position: absolute;
  top: 6px;
  right: 10px;
}

/*
  dashboard page
*/
.hero-section {
  background: url("../images/9.jpg");
  display: block;
  padding: 50px 0;
  position: relative;
  background-size: cover;
  background-attachment: fixed;
  background-position: center;
  /*
    profile progressbar
    */
}

.hero-section:before {
  content: '';
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  background: linear-gradient(89deg, #154abd 0.1%, #1a8ad3 51.5%, #48b1ea 100.2%);
  opacity: .9;
}

.hero-section .progress,
.hero-section .profile-item {
  width: 150px;
  height: 150px;
  background: none;
  margin: 0 auto;
  box-shadow: none;
  position: relative;
}

.hero-section .progress .rounded-circle,
.hero-section .profile-item .rounded-circle {
  border-radius: 50% !important;
  top: 5px;
  position: relative;
  max-width: 143px;
}

.hero-section .progress .profile-thumb-edit.verify-icon,
.hero-section .profile-item .profile-thumb-edit.verify-icon {
  position: absolute;
  width: 35px;
  height: 35px;
  font-size: 17px;
  border-radius: 50%;
  padding: 8px;
  text-align: center;
  right: 7px;
}

.hero-section .progress:after {
  content: "";
  width: 100%;
  height: 100%;
  border-radius: 50%;
  border: 7px solid #eee;
  position: absolute;
  top: 0;
  left: 0;
}

.hero-section .progress > span {
  width: 50%;
  height: 100%;
  overflow: hidden;
  position: absolute;
  top: 0;
  z-index: 1;
}

.hero-section .progress .progress-left {
  left: 0;
}

.hero-section .progress .progress-bar {
  width: 100%;
  height: 100%;
  background: none;
  border-width: 7px;
  border-style: solid;
  position: absolute;
  top: 0;
  border-color: #2ed06e;
}

.hero-section .progress .progress-left .progress-bar {
  left: 100%;
  border-top-right-radius: 75px;
  border-bottom-right-radius: 75px;
  border-left: 0;
  -webkit-transform-origin: center left;
  transform-origin: center left;
}

.hero-section .progress .progress-right {
  right: 0;
}

.hero-section .progress .progress-right .progress-bar {
  left: -100%;
  border-top-left-radius: 75px;
  border-bottom-left-radius: 75px;
  border-right: 0;
  -webkit-transform-origin: center right;
  transform-origin: center right;
}

.hero-section .progress .progress-value {
  display: flex;
  border-radius: 50%;
  font-size: 36px;
  text-align: center;
  line-height: 20px;
  align-items: center;
  justify-content: center;
  height: 100%;
  font-weight: 300;
}

.hero-section .progress .progress-value div {
  margin-top: 10px;
}

.hero-section .progress .progress-value span {
  font-size: 12px;
  text-transform: uppercase;
}

.hero-section .progress[data-percentage="10"] .progress-right .progress-bar {
  animation: loading-1 1.5s linear forwards;
}

.hero-section .progress[data-percentage="10"] .progress-left .progress-bar {
  animation: 0;
}

.hero-section .progress[data-percentage="20"] .progress-right .progress-bar {
  animation: loading-2 1.5s linear forwards;
}

.hero-section .progress[data-percentage="20"] .progress-left .progress-bar {
  animation: 0;
}

.hero-section .progress[data-percentage="30"] .progress-right .progress-bar {
  animation: loading-3 1.5s linear forwards;
}

.hero-section .progress[data-percentage="30"] .progress-left .progress-bar {
  animation: 0;
}

.hero-section .progress[data-percentage="40"] .progress-right .progress-bar {
  animation: loading-4 1.5s linear forwards;
}

.hero-section .progress[data-percentage="40"] .progress-left .progress-bar {
  animation: 0;
}

.hero-section .progress[data-percentage="50"] .progress-right .progress-bar {
  animation: loading-5 1.5s linear forwards;
}

.hero-section .progress[data-percentage="50"] .progress-left .progress-bar {
  animation: 0;
}

.hero-section .progress[data-percentage="60"] .progress-right .progress-bar {
  animation: loading-5 1.5s linear forwards;
}

.hero-section .progress[data-percentage="60"] .progress-left .progress-bar {
  animation: loading-1 1.5s linear forwards 1.5s;
}

.hero-section .progress[data-percentage="70"] .progress-right .progress-bar {
  animation: loading-5 1.5s linear forwards;
}

.hero-section .progress[data-percentage="70"] .progress-left .progress-bar {
  animation: loading-2 1.5s linear forwards 1.5s;
}

.hero-section .progress[data-percentage="80"] .progress-right .progress-bar {
  animation: loading-5 1.5s linear forwards;
}

.hero-section .progress[data-percentage="80"] .progress-left .progress-bar {
  animation: loading-3 1.5s linear forwards 1.5s;
}

.hero-section .progress[data-percentage="90"] .progress-right .progress-bar {
  animation: loading-5 1.5s linear forwards;
}

.hero-section .progress[data-percentage="90"] .progress-left .progress-bar {
  animation: loading-4 1.5s linear forwards 1.5s;
}

.hero-section .progress[data-percentage="100"] .progress-right .progress-bar {
  animation: loading-5 1.5s linear forwards;
}

.hero-section .progress[data-percentage="100"] .progress-left .progress-bar {
  animation: loading-5 1.5s linear forwards 1.5s;
}

@keyframes loading-1 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(36);
    transform: rotate(36deg);
  }
}

@keyframes loading-2 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(72);
    transform: rotate(72deg);
  }
}

@keyframes loading-3 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(108);
    transform: rotate(108deg);
  }
}

@keyframes loading-4 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(144);
    transform: rotate(144deg);
  }
}

@keyframes loading-5 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(180);
    transform: rotate(180deg);
  }
}

.hero-section .progress {
  margin-bottom: 1em;
}

.hero-section .profile-completeness .border {
  /* border: none !important; */
  width: 150px;
  height: 150px;
  border-radius: 50% !important;
  margin: 0 auto;
  position: relative;
}

.hero-section .profile-completeness .border.profile-step a {
  color: #fff;
  display: block;
  cursor: pointer;
  position: relative;
  z-index: 9;
}

.hero-section .custom-file {
  position: relative;
  z-index: 2;
  bottom: 7px;
}

.hero-section .bg-icon {
  position: relative;
  font-size: 40px;
  left: 0;
  right: 0;
  margin: auto;
  /*transform: rotate(-45deg);*/
}

.hero-section .profile-name {
  display: block;
  text-align: center;
  color: #fff;
  position: absolute;
  left: 0;
  right: 0;
  bottom: -20px;
  margin: 0;
}

.hero-section .profile-item {
  background: #ffffff96;
  border-radius: 50%;
  text-align: center;
  display: block;
  position: relative;
}

.hero-section .profile-item:hover {
  background: #fff;
}

.hero-section .profile-item:hover i {
  color: #154abd;
}

.hero-section .profile-item i {
  display: block;
  top: 50px;
  font-size: 50px;
}

.hero-section .profile-item i.Verified-icon {
  background: #fff;
  border-radius: 50%;
  position: absolute;
  font-size: 24px;
  margin-top: 15px;
  top: 55%;
  right: 0px;
}

.hero-section .profile-item i.Verified-icon.fa-check-circle {
  color: #2ed06e;
}

.hero-section .profile-item .bg-icon {
  color: #fff;
}

.hero-section .profile-item p.title {
  display: block;
  text-align: center;
  color: #fff;
  position: absolute;
  left: 0;
  right: 0;
  bottom: -50px;
}

.admin-heading {
  display: block;
  margin-bottom: 15px;
  padding: 10px 15px;
  border-left: 10px solid #154abd;
  font-size: 20px;
  box-shadow: 0 5px 10px rgba(24, 33, 99, 0.1);
  background: #f7faff;
  position: relative;
}

.admin-heading:before {
  position: absolute;
  content: '';
  width: 100%;
  height: 100%;
  display: block;
  top: 0;
  left: 0;
  border: 1px solid #edebeb;
}

.transaction-title {
  display: block;
  font-weight: 700;
}

.transaction-title .items {
  margin-bottom: 5px;
  padding: 10px;
  transition: all 300ms ease-in-out;
  border-radius: 5px;
  box-shadow: 0 5px 10px rgba(24, 33, 99, 0.1);
  border: 1px solid #edebeb;
}

.transaction-title .col:first-child {
  max-width: 90px;
  text-align: left;
}

.transaction-title .col:nth-child(3) {
  max-width: 90px;
  text-align: left;
}

.transaction-title .col:nth-child(4) {
  max-width: 150px;
  text-align: left;
}

button.close {
  position: absolute;
  right: 0;
  top: 0;
  padding: 8px 10px !important;
  margin: 0 !important;
  clip-path: polygon(100% 0, 0 0, 100% 100%);
  background: linear-gradient(89deg, #154abd 0.1%, #1a8ad3 51.5%, #48b1ea 100.2%);
  width: 50px;
  height: 50px;
  opacity: 1;
}

button.close span {
  top: 4px;
  right: 7px;
  position: absolute;
  color: #fff;
}

.modal-content {
  overflow: hidden;
}

.modal_header {
  padding: 30px;
  display: block;
  background: #f6f7f8;
  margin-bottom: 15px;
}

.modal_header .status {
  position: absolute;
  height: 25px;
  width: 100px;
  display: block;
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
  text-align: center;
  color: #fff;
  left: -5%;
  top: 2%;
}

.transaction-area .items {
  border: 1px solid #edebeb;
  box-shadow: 0 5px 10px rgba(24, 33, 99, 0.1);
  margin-bottom: 15px;
  padding: 10px;
  transition: all 300ms ease-in-out;
  border-radius: 5px;
}

.transaction-area .items:first-child {
  border-radius: 0 0 5px 5px;
}

.transaction-area .items .col:first-child {
  max-width: 90px;
  text-align: left;
}

.transaction-area .items .col:nth-child(4) {
  max-width: 150px;
  text-align: left;
}

.transaction-area .items .col:nth-child(3) {
  max-width: 90px;
  text-align: left;
}

.transaction-area .items:hover {
  background: linear-gradient(89deg, #154abd 0.1%, #1a8ad3 51.5%, #48b1ea 100.2%);
  color: #fff;
}

.transaction-area .items .payments-status {
  display: block;
  cursor: pointer;
}

.sidebar .admin-widget {
  text-align: center;
  box-shadow: 0 5px 10px rgba(24, 33, 99, 0.1);
  padding: 50px 15px;
  margin-bottom: 30px;
  border: 1px solid #edebeb;
}

.sidebar .admin-widget i.admin-overlay-icon {
  position: absolute;
  font-size: 100px;
  text-align: center;
  opacity: .1;
  left: 0;
  right: 0;
  margin: auto;
}

.sidebar .Profile-menu ul li a i {
  width: 24px;
  text-align: left;
}

.sidebar .Profile-menu ul li a {
  width: 100%;
  text-align: left !important;
  color: #fff !important;
  padding: 10px 15px !important;
  background: linear-gradient(89deg, #154abd 0.1%, #1a8ad3 51.5%, #48b1ea 100.2%);
  font-size: 14px !important;
}

.sidebar .Profile-menu ul li.active a {
  position: relative;
  background: linear-gradient(89deg, #154abd 0.1%, #154abd 51.5%, #154abd 100.2%);
}

.sidebar .Profile-menu ul li.active a:before {
  content: '';
  width: 5px;
  height: 100%;
  position: absolute;
  left: 0;
  display: block;
  background: #256be6;
  top: 0;
}

.table .table-head {
  display: block;
  padding: 5px 15px;
}

.table .table-content {
  border-bottom: 1px solid #ddd;
  padding: 10px;
  -webkit-transition: all 300ms ease-in-out;
  transition: all 300ms ease-in-out;
  border-radius: 5px;
}

.table .table-sub {
  border-bottom: 1px solid #ddd;
}

.profile-area .infoItems {
  margin-bottom: 30px;
}

.profile-area .accord {
  position: relative;
  padding: 15px;
  margin-bottom: 30px;
}

.profile-area .accord button.close {
  margin: 0;
}

.profile-area .accord .btn {
  display: block;
  width: 100%;
}

.profile-area .accord .content-edit-area {
  display: block;
  margin-bottom: 30px;
  border-bottom: 1px solid #ddd;
  overflow: hidden;
  background: #fff;
  padding: 15px;
  box-shadow: 0 5px 10px rgba(24, 33, 99, 0.1);
}

.profile-area a.btn-link.pbtn {
  font-size: 14px;
  float: right;
  margin-top: 7px;
  position: relative;
}

.accord {
  position: relative;
  border-bottom: 1px solid #ddd;
}

.accord .form-control, .accord .custom-select {
  padding: .375rem .75rem;
}

.account-card {
  display: block;
  background: linear-gradient(89deg, #154abd 0.1%, #1a8ad3 51.5%, #48b1ea 100.2%);
  color: #fff;
  padding: 15px;
  position: relative;
  overflow: hidden;
}

.account-card p.card-holder-name {
  margin-bottom: 0;
}

.account-card img {
  width: auto;
  max-width: 100%;
}

.account-card .account-card-overlay {
  display: none;
  position: absolute;
  left: 0;
  bottom: 0;
  background: #000000d6;
  width: 100%;
  height: 40px;
  text-align: center;
  color: #fff;
}

.account-card .account-card-overlay a {
  position: relative;
  position: relative;
  right: auto;
  top: 7px;
  color: #fff;
}

.account-card:hover .account-card-overlay {
  display: block;
}

.account-card p.valid-card {
  font-size: 10px;
  width: 30px;
  text-transform: uppercase;
  opacity: .3;
  line-height: 1;
  float: left;
  margin-right: 6px;
}

.account-card a.account-card-new {
  position: relative;
  text-align: center;
  color: #fff !important;
  top: auto;
  right: auto;
  padding: 14px 0;
}

.account-card a.account-card-new p {
  width: 100%;
  display: block;
  color: #fff;
}

.account-card p.pirotry.text-right {
  position: absolute;
  width: 100px;
  height: 25px;
  background: #2ed06e;
  transform: rotate(45deg);
  text-align: center !important;
  right: -25px;
  font-size: 11px;
  top: 15px;
}

.main-form.all-fillters-area {
  display: block;
  position: relative;
  top: 0;
  z-index: 1;
}

.main-form.all-fillters-area ul.morebtn {
  float: left;
  width: 40px;
}

.main-form.all-fillters-area ul.morebtn li {
  width: 40px;
  height: 40px;
  display: flex;
  position: relative;
  text-align: center;
  cursor: pointer;
  background: #f6f7f8;
  color: #154abd;
  padding: 5px 5px;
  outline: none;
  border: none;
}

.main-form.all-fillters-area ul.morebtn li a {
  padding: 2px;
  text-align: center;
}

.main-form.all-fillters-area ul.morebtn li.active {
  background: #fff;
  color: #154abd;
}

.main-form.all-fillters-area ul.morebtn li:hover ul {
  display: block;
}

.main-form.all-fillters-area ul.morebtn li ul {
  margin: 0;
  padding: 0;
  left: auto;
  right: 0;
  width: 150px;
}

.main-form.all-fillters-area ul.morebtn li ul li {
  display: block;
  width: 100%;
  height: auto;
  padding: 5px 10px;
  display: block;
  text-align: left;
  background: #154abd;
  color: #fff;
}

.main-form.all-fillters-area ul.morebtn li ul li:hover {
  color: #4facfe;
  background: #fff;
}

.main-form.all-fillters-area .bill-items {
  position: relative;
  max-width: calc(100% - 40px);
  float: left;
}

.main-form.all-fillters-area .bill-items li {
  width: 87px;
  height: 40px;
  display: flex;
  font-size: 14px;
  cursor: pointer;
  justify-content: center;
  background: #f6f7f8;
  color: #154abd;
  padding: 7px 10px;
  outline: none;
  border: none;
}

.main-form.all-fillters-area .bill-items li.active {
  background: linear-gradient(89deg, #154abd 0.1%, #1a8ad3 51.5%, #48b1ea 100.2%);
  color: #fff;
}

.main-form.all-fillters-area .bill-items li:hover {
  border: none;
  outline: none;
  background: #fff;
  color: #154abd;
}

.all-fillters-area {
  margin-bottom: 15px;
}

#custom-filter-area {
  margin-bottom: 15px;
}

#custom-filter-area .filter-area {
  position: relative;
}

#custom-filter-area .filter-area i.fa-calendar-alt {
  font-size: 20px;
  opacity: .7;
  position: absolute;
  top: 16px;
  left: 15px;
}

#custom-filter-area .filter-area .export-area {
  display: inline-block;
  position: absolute;
  top: 12px;
  right: 15px;
}

#custom-filter-area .filter-area input#custom-date {
  padding-left: 45px;
}

.daterangepicker .ranges li.active {
  background: linear-gradient(89deg, #154abd 0.1%, #1a8ad3 51.5%, #48b1ea 100.2%);
}

#notifications-box .custom-control-label::before, #notifications-box .custom-control-label::after {
  width: 3rem;
  height: 3rem;
}

#notifications-box .custom-control-label::before {
  background: linear-gradient(89deg, #154abd 0.1%, #1a8ad3 51.5%, #48b1ea 100.2%);
}

#notifications-box .custom-control-label {
  padding-left: 35px;
}

#notifications-box p {
  padding-left: 35px;
}

#notifications-box .noti-items {
  padding: 15px;
  display: block;
  margin-bottom: 30px;
  padding-bottom: 10px;
  box-shadow: 0 5px 10px rgba(24, 33, 99, 0.1);
}

#notifications-box .noti-items p {
  margin-bottom: 0;
}

.profile-content {
  padding: 0;
  display: block;
  position: relative;
  /* Cards & Bank Accounts*/
}

.profile-content .form {
  margin: 15px 0;
  padding: 30px;
  padding-top: 0;
  box-shadow: 0 5px 10px rgba(24, 33, 99, 0.1);
  border: 1px solid #edebeb;
}

.profile-content .form p {
  margin-bottom: 0;
}

.profile-content .form.success {
  padding-top: 60px;
  padding-bottom: 60px;
}

.profile-content .form.success p {
  margin-bottom: 10px;
}

.profile-content .form.success .fa-check-circle {
  font-size: 100px;
}

.profile-content .form#request-send-money p {
  margin-bottom: 10px;
}

.profile-content .form span.bg-success.free-charge {
  color: #fff;
  padding: 2px 5px;
  border-radius: 3px;
  font-size: 12px;
  position: relative;
  top: -2px;
}

.profile-content .form span.icon-inside {
  position: absolute;
  top: 16px;
  right: 15px;
}

.profile-content .form .Withdarw-header {
  background: linear-gradient(89deg, #154abd 0.1%, #1a8ad3 51.5%, #48b1ea 100.2%);
  text-align: center;
  color: #fff;
  padding: 30px;
}

.profile-content .form .Withdarw-header h3 {
  color: #fff;
}

.profile-content .form .Withdarw-header .av-balance {
  margin-top: 10px;
  margin: 0;
}

.profile-content .form .amaount-area {
  width: calc(100% - 277px);
  position: relative;
}

.profile-content .form .amaount-area a.full-amnt {
  position: absolute;
  right: 15px;
  top: 16px;
}

.profile-content .coupon-box {
  display: none;
}

.profile-content .coupon-box .form-control {
  padding: 15px !important;
}

.profile-content .pager {
  display: flex;
}

.profile-content .pager li {
  width: 50%;
  text-align: start;
}

.profile-content .pager li:last-child {
  text-align: end;
}

.profile-content .products .row a {
  flex-grow: 1;
  height: 90px;
  border: 1px solid #cccccc;
  border-radius: 5px;
  background: transparent;
  font-weight: 500;
  display: flex;
  color: #202020;
  font-size: 18px;
  text-align: center;
  justify-content: center;
  align-items: center;
  box-shadow: 0 10px 30px rgba(24, 33, 99, 0.1);
}

.profile-content .products .row a:hover, .profile-content .products .row a.selected {
  background: linear-gradient(89deg, #154abd 0.1%, #1a8ad3 51.5%, #48b1ea 100.2%);
  color: #fff;
}

.profile-content .products .row a.pakbtn {
  display: block !important;
  width: 100%;
  height: 90px;
  border: 1px solid #cccccc;
  border-radius: 5px;
  font-weight: 500;
  font-size: 18px;
  padding-top: 18px;
  box-shadow: 0 10px 30px rgba(24, 33, 99, 0.1);
}

.profile-content .products .row a.pakbtn b,
.profile-content .products .row a.pakbtn span {
  width: 100%;
}

.profile-content .products .row a.pakbtn:hover {
  background: linear-gradient(89deg, #154abd 0.1%, #1a8ad3 51.5%, #48b1ea 100.2%);
  color: #fff;
}

.profile-content .nav-pills {
  border: 1px solid #edebeb;
  overflow: hidden;
  box-shadow: 0 5px 10px rgba(24, 33, 99, 0.1);
}

.profile-content .nav-pills .nav-item {
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

.profile-content .nav-pills .nav-item .nav-link {
  border-radius: 0;
  padding: 15px 0;
  text-align: center;
  position: relative;
  color: #5c5c5c;
  text-transform: capitalize;
  clip-path: polygon(75% 0%, 100% 50%, 75% 100%, 0% 100%, 25% 50%, 0% 0%);
}

.profile-content .nav-pills .nav-item .nav-link.active {
  background: linear-gradient(89deg, #154abd 0.1%, #1a8ad3 51.5%, #48b1ea 100.2%);
  color: #fff;
  clip-path: polygon(75% 0%, 100% 50%, 75% 100%, 0% 100%, 25% 50%, 0% 0%);
}

.profile-content .nav-pills .nav-item:last-child .nav-link.active {
  clip-path: polygon(100% 0%, 100% 50%, 100% 100%, 0% 100%, 25% 50%, 0% 0%);
}

.profile-content .tab-content .tab-pane .form-group {
  margin-top: 32px;
}

.profile-content .tab-content .tab-pane .form-group label {
  font-size: 21px;
  line-height: 31px;
  color: #202020;
  font-weight: 500;
  margin-bottom: 10px;
}

.profile-content span.input-group-text.currency-icon {
  width: 50px;
  background: #154abd;
  color: #fff;
  font-size: 20px;
  justify-content: center;
}

.profile-content span.input-group-text {
  padding: 0;
  height: 60px;
  background: #fff;
}

.profile-content .custom-select {
  height: 60px;
}

.profile-content .tab-content .tab-pane .form-group .form-control {
  border-radius: 5px;
  height: 60px;
  -webkit-box-shadow: none;
  box-shadow: none;
  border: 1px solid #ddd;
  padding: 0 20px;
  box-shadow: 0 10px 30px rgba(24, 33, 99, 0.1);
}

.profile-content .tab-content .tab-pane .form-group .custom-select {
  box-shadow: 0 10px 30px rgba(24, 33, 99, 0.1);
}

.profile-content .tab-content .tab-pane .form-group textarea.form-control {
  height: 120px;
}

.profile-content .tab-content .tab-pane .form-group .order-page-box {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.profile-content .tab-content .tab-pane .form-group .order-page-box .form-control {
  border-radius: 0;
  height: 60px;
  -webkit-box-shadow: none;
  box-shadow: none;
  border: 1px solid #ddd;
}

.profile-content .tab-content .tab-pane .form-group .order-page-box .form-control:first-child {
  width: 120px;
  text-align: center;
  border-radius: 5px 0 0 5px;
  border-right: none;
  padding: 0 15px;
}

.profile-content .tab-content .tab-pane .form-group .order-page-box .form-control:last-child {
  border-radius: 0 5px 5px 0;
  padding: 0 30px;
}

.profile-content .tab-content .tab-pane .form-group .order-page-box .form-control:focus {
  border-color: #ddd;
}

.profile-content .tab-content .tab-pane .operators .title {
  font-size: 21px;
  line-height: 31px;
  color: #202020;
  font-weight: 500;
  margin-bottom: -10px;
  margin-top: 30px;
}

.profile-content .tab-content .tab-pane .operators .single-operator {
  border-radius: 5px;
  text-align: center;
  margin-top: 30px;
}

.profile-content .tab-content .tab-pane .operators .single-operator .op-logo {
  border: 10px solid #ddd;
  cursor: pointer;
  box-shadow: 0 10px 30px rgba(24, 33, 99, 0.1);
}

.profile-content .tab-content .tab-pane .operators .single-operator .op-logo.selected {
  border-color: #48b1ea;
}

.profile-content .tab-content .tab-pane .operators .single-operator h3 {
  color: #202020;
  font-size: 16px;
  font-weight: 500;
}

.profile-content .tab-content .tab-pane .products h3.title {
  font-size: 21px;
  line-height: 31px;
  font-weight: 500;
  margin-bottom: 10px;
  margin-top: 30px;
}

.profile-content .tab-content .tab-pane .products button {
  height: 90px;
  border: 1px solid #ddd;
  border-radius: 5px;
  font-weight: 500;
  font-size: 18px;
  margin-right: 18px;
  box-shadow: 0 10px 30px rgba(24, 33, 99, 0.1);
}

.profile-content .tab-content .tab-pane .products span {
  display: block;
  font-size: 16px;
}

.profile-content .tab-content .tab-pane .order-summery h3.title {
  font-size: 21px;
  line-height: 31px;
  color: #202020;
  font-weight: 500;
  margin-bottom: 10px;
  margin-top: 30px;
}

.profile-content .tab-content .tab-pane .order-summery .table thead {
  background: #f1f1f1;
}

.profile-content .tab-content .tab-pane .order-summery .table thead tr th {
  color: #202020;
  font-size: 16px;
  font-weight: 500;
  border: none;
  padding: 18px 30px;
}

.profile-content .tab-content .tab-pane .order-summery .table tbody tr:last-child {
  border-bottom: 1px solid #ddd;
}

.profile-content .tab-content .tab-pane .order-summery .table tbody tr th,
.profile-content .tab-content .tab-pane .order-summery .table tbody tr td {
  color: #5c5c5c;
  font-weight: 400;
  padding: 18px 30px;
}

.profile-content .tab-content .tab-pane .buttons {
  text-align: center;
  margin-top: 40px;
}

.profile-content .tab-content .tab-pane .payment h3.title {
  font-size: 21px;
  line-height: 31px;
  color: #202020;
  font-weight: 500;
  margin-bottom: 22px;
  margin-top: 30px;
}

.profile-content .tab-content .tab-pane .payment .single-payment {
  text-align: center;
  padding: 10px;
  border: 10px solid #ddd;
  border-radius: 5px;
  margin-bottom: 30px;
  min-height: 80px;
  position: relative;
  cursor: pointer;
}

.profile-content .tab-content .tab-pane .payment .single-payment.selected {
  border-color: #48b1ea;
}

.profile-content .tab-content .tab-pane .payment .single-payment img {
  position: absolute;
  width: calc(100% - 20px);
  top: 50%;
  transform: translateY(-50%);
}

.profile-content .tab-content .tab-pane .payment .single-payment h4 {
  color: #202020;
  text-transform: capitalize;
  font-size: 21px;
  margin-bottom: 20px;
}

.profile-content .tab-content .tab-pane .credit-card {
  padding: 30px;
  border: 1px solid #ddd;
  border-radius: 5px;
  margin-bottom: 30px;
}

.profile-content .tab-content .tab-pane .credit-card h3.title {
  font-size: 21px;
  line-height: 31px;
  color: #202020;
  font-weight: 500;
  margin-bottom: 22px;
  margin-top: 0px;
}

.profile-content .tab-content .tab-pane .credit-card .form-group {
  margin-top: 0;
}

.profile-content .tab-content .tab-pane .credit-card .form-group label {
  font-size: 16px;
  margin-bottom: 10px;
  color: #5c5c5c;
}

.profile-content .tab-content .tab-pane .done {
  text-align: center;
}

.profile-content .tab-content .tab-pane .done i {
  font-size: 100px;
}

.profile-content .tab-content .tab-pane .done h3.title {
  font-size: 21px;
  line-height: 31px;
  color: #202020;
  font-weight: 500;
  margin-bottom: 22px;
  margin-top: 30px;
}

.profile-content .tab-content .tab-pane .done img {
  margin-bottom: 20px;
}

.profile-content .tab-content .tab-pane .done p {
  line-height: 26px;
}

.profile-content .tab-content .tab-pane .invoice-option a.invoice-btn {
  color: #154abd;
  margin: 5px;
  font-size: 16px;
}

.profile-content .tab-content .tab-pane .invoice-option a.invoice-btn i {
  margin-right: 3px;
  display: inline-block;
  font-size: 16px;
}

.profile-content .account-card {
  position: relative;
  background: linear-gradient(-45deg, #6c6c6b, #9e9e9c);
}

.profile-content .account-card.account-card-primary {
  background: linear-gradient(89deg, #154abd 0.1%, #1a8ad3 51.5%, #48b1ea 100.2%);
}

.profile-content .account-card .account-card-expire {
  font-size: 8px;
  line-height: 10px;
}

.profile-content .account-card .account-card-overlay {
  position: absolute;
  background: rgba(0, 0, 0, 0.85);
  top: 0px;
  left: 0px;
  height: 100%;
  width: 100%;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: center;
  justify-content: center;
  display: -ms-flexbox;
  display: flex;
  opacity: 0;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.profile-content .account-card:hover .account-card-overlay {
  opacity: 1;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.profile-content .account-card-new {
  background: #f1f5f6;
  border: 1px solid #ebebeb;
}

.profile-content .account-card .border-left,
.profile-content .account-card .border-right {
  border-color: rgba(250, 250, 250, 0.1) !important;
}

.profile-content #filterTransactions .icon-inside {
  top: 13px;
  right: 15px;
}
